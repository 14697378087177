import { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import useSiteSelectorQuery from 'queries/useSiteSelectorQuery';
import Image from '@activebrands/core-web/components/Image';
import media from '@activebrands/core-web/config/media';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import SiteSelector from 'components/Header/Navigation/SiteSelector';
import PrimaryMenuHeading from 'components/Header/PrimaryMenuHeading';
import ThemeButton from 'components/buttons/ThemeButton';
import CloseIcon from 'components/icons/CloseIcon';
import PlusIcon from 'components/icons/PlusIcon';
import Paragraph from 'components/text/Paragraph';

const Wrapper = styled('div', {
    position: 'relative',
    background: 'var(--color-bg-header)',
    width: 'var(--width-overlays)',
    height: `calc(var(--vh) - var(--height-header) - 8px)`, //8px since there is no gap around header on mobile

    [media.min['desktop.sm']]: {
        margin: '8px 0',
        height: `calc(var(--vh) - var(--height-header) - 12px)`,
    },
});

const SiteSelectorWrapper = styled('div', {
    position: 'absolute',

    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '1px solid var(--color-border-navigation)',
    backgroundColor: 'var(--color-bg-navigation)',
    zIndex: 1,
    bottom: '0',
});

const SiteSelectorOverlay = ({ alternateHrefs = [] }) => {
    const [css] = useStyletron();
    const {
        siteSelectorHeading,
        siteSelectorHeadingType,
        siteSelectorLinks,
        siteSelectorParagraph,
        siteSelectorRedirectLabel,
    } = useSiteSelectorQuery();

    const [isSiteSelectorOpen, setIsSiteSelectorOpen] = useState(true);
    const aliasId = useSelector(state => state.application.aliasId);
    const currentSite = siteSelectorLinks.find(site => site.id == aliasId);

    const closeSiteSelector = () => {
        overlay.close('site-selector');
    };

    return (
        <Wrapper>
            <PrimaryMenuHeading
                isSiteSelectorOpen={isSiteSelectorOpen}
                closeSiteSelector={closeSiteSelector}
                $style={{
                    display: ['flex', null, null, null, 'none'],
                    zIndex: 2,
                    borderBottom: '1px solid var(--color-border-navigation)',
                }}
            />
            <SiteSelector
                alternateHrefs={alternateHrefs}
                isSiteSelectorOpen={isSiteSelectorOpen}
                setIsSiteSelectorOpen={setIsSiteSelectorOpen}
                siteSelectorHeading={siteSelectorHeading}
                siteSelectorHeadingType={siteSelectorHeadingType}
                siteSelectorParagraph={siteSelectorParagraph}
                siteSelectorRedirectLabel={siteSelectorRedirectLabel}
            />
            <SiteSelectorWrapper>
                <ThemeButton
                    active={isSiteSelectorOpen}
                    $style={{
                        width: '100%',
                        padding: '16px 20px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderStyle: 'none',
                    }}
                    theme="none"
                    onClick={() => closeSiteSelector()}
                >
                    <div className={css({ display: 'flex' })}>
                        <div className={css({ marginRight: '12px', width: '24px', height: '16px' })}>
                            <Image sizes={['24px']} src={{ url: currentSite?.icon, width: [24, 48, 72] }} />
                        </div>
                        <Paragraph fontKeys="Primary/14_100_-3">{currentSite?.label}</Paragraph>
                    </div>
                    {!!isSiteSelectorOpen ? <CloseIcon width="12px" /> : <PlusIcon width="12px" />}
                </ThemeButton>
            </SiteSelectorWrapper>
        </Wrapper>
    );
};

SiteSelectorOverlay.propTypes = {
    alternateHrefs: PropTypes.array,
};

export default SiteSelectorOverlay;
